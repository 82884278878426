import {useMediaStore} from "~/stores/media"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const mediaStore = useMediaStore()

    const id = getSingleValue(to.params.id)

    if (stringIsInteger(id)) {
        const mediaData = await mediaStore.getMedia(Number(to.params.id), true, getSingleValue(to.query.auth), getSingleValue(to.query.dir))
        if (!mediaData) {
            abortNavigation(createError({ statusCode: 404, statusMessage: `Could not find media with ID ${to.params.id}` }))
        }
    } else {
        abortNavigation(createError({ statusCode: 404, statusMessage: `Invalid media ID` }))
    }

})
